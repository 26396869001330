<template>
  <div>
    <!-- <base-loader :isLoading="isLoading"></base-loader> -->
    <v-row>
      <v-col cols="12">
        <v-card v-if="notifications">
          <v-list-item-group>
            <!-- .filter((item) => {
              return item.assignedTo == selectedItem.id; -->
            <!-- })" -->
            <v-list-item v-for="i in notifications.records" :key="i.id">
              <template v-slot:default="{ active }">
                <v-list-item-content width="100vw">
                  <v-card width="100vw" class="my-1">
                    <v-list-item-title>
                      <v-row align="center" justify="center"
                        ><v-col cols="9"
                          ><div>
                            {{ description }}
                            <h2
                              class="mt-2 purple--text"
                              v-if="i.description !== 'Employee checked in '"
                            >
                              {{ i.description }}
                            </h2>
                            <h2 class="mt-2 black--text" v-else>
                              {{ i.description }}
                            </h2>
                            <br />
                            <v-row class="mt-2">
                              <v-col cols="9">
                                <p>
                                  Created at
                                  {{ i.createdAt | getFormattedYYYYMMDD }}
                                </p>
                              </v-col>
                              <v-col cols="3" v-if="i.done == true"
                                ><p class="green--text">
                                  Alert acknowledged
                                </p></v-col
                              >
                              <v-col cols="3" v-else
                                ><p class="red--text">Still to action</p></v-col
                              >
                            </v-row>
                          </div>
                        </v-col>
                        <!-- <v-col cols="3"> <base-update-notifications /> </v-col
                    > -->
                        <!-- <v-form ref="notificationForm" @submit.prevent="save()"> -->
                        <!-- <v-form ref="notificationForm" @submit.prevent="save()"> -->
                        <v-checkbox
                          @click="save()"
                          v-model="i.read"
                          color="primary"
                        ></v-checkbox>
                        <!-- </v-form> -->
                      </v-row>
                    </v-list-item-title>
                  </v-card>
                </v-list-item-content>

                <!-- <v-spacer></v-spacer> <v-spacer></v-spacer
                  ><v-btn icon @click="show = !show">
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn></v-card-actions
                ><v-expand-transition>
                  <div v-show="show">
                    <v-divider></v-divider>

                    <v-card-text>
                     <p>read: {{ i.read }}</p>
                    </v-card-text>
                  </div>
                </v-expand-transition> -->
              </template>
            </v-list-item>
          </v-list-item-group></v-card
        >
        <v-card v-else width="100vw"><p>No notifications yet</p></v-card>

        <!-- <v-btn x-small fab color="green lighten-2" @click="save()"
          ><v-icon>mdi-eye</v-icon></v-btn
        > -->
      </v-col>
      <!-- <v-col
        cols="6"
        md="6"
        class="absolute-center"
        v-if="
          $vuetify.breakpoint.md ||
          $vuetify.breakpoint.lg ||
          $vuetify.breakpoint.xl
        "
      >
        <v-img
          contain
          src="../../assets/FairwageLogo.png"
          lazy-src="../../assets/FairwageLogo.png"
        ></v-img>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import { Notification } from "@/models/Notification";

export default {
  name: "Employer Notifications_2",
  mounted() {
    this.$emit("update:pageName", this.$options.name);
  },

  data() {
    return {
      isEdit: false,
      isLoading: false,
      notificationModel: new Notification(),
      me: "",
    };
  },
  created() {
    this.isEdit = this.$route.params.id != 0 && this.$route.params.id != null;
  },

  apollo: {
    me: gql`
      query {
        me {
          id
          firstName
          lastName
        }
      }
    `,
    notifications: {
      query: gql`
        query Notifications($limit: Int!, $skip: Int!, $query: JSON!) {
          notifications(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              read
              description
              assignedTo
              createdAt
              updatedAt
              updatedBy
              # createdBy
              # createdByName
              updatedByName
              assignedTo
              employerAssignedTo
            }
            count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 1000;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: { employerAssignedTo: this.me.id },
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
    notification: {
      query: gql`
        query Notification($id: ID!) {
          notification(id: $id) {
            id
            description
            read
            done
            employerAssignedTo
            assignedTo
            updatedBy
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        const self = this;
        return {
          id: this.isEdit ? this.notificationModel.id : self.$route.params.id,
        };
      },
      result(response, key) {
        //  alert(JSON.stringify(this.notification));
        this.isLoading = false;
        this.notificationModel.id = this.notification.id;
        this.notificationModel.description = this.notification.description;
        this.notificationModel.done = this.notification.done;
        this.notificationModel.read = this.notification.read;
        this.notificationModel.assignedTo = this.notification.assignedTo;
        this.notificationModel.employerAssignedTo =
          this.notification.employerAssignedTo;
        this.notificationModel.updatedBy = this.notification.updatedBy;
      },
    },
  },

  methods: {
    async save() {
      const self = this;
      // if (!self.$refs.notificationForm.validate()) {
      //   self.$swal("Error", "Validation failed", "error");
      //   self.isLoading = false;
      //   return;
      // }
      if (self.isEdit) {
        // update
        await this.updateNotification()
          .then(() => {
            this.$swal({
              title: "Alert acknowledged",
              text: "The status of your notification was updated, click ok to refresh",
              type: "success",
              showConfirmButton: true,
              showCancelButton: false,
              icon: "success",
              timer: 1500,
            }).then(
              function () {},
              // handling the promise rejection
              function (dismiss) {
                if (dismiss === "timer") {
                  this.$router.go(this.$route.currentRoute);
                }
              }
            );
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              "something went wrong when trying to acknowledge the alert, please try again later!",
              "error"
            );
          })
          .then(function () {
            this.$router.go(this.$route.currentRoute);
          });
      }
    },
    async updateNotification() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation update($notification: NotificationUpdateInput!) {
              updateNotification(notification: $notification) {
                id
                updated
              }
            }
          `,
          variables: {
            notification: {
              id: self.notificationModel.id,
              // description: self.notificationModel.description,
              // done: self.notificationModel.done,
              read: self.notificationModel.read,
              employerAssignedTo: self.me.id,
              assignedTo: self.notificationModel.assignedTo,
              updatedBy: self.me.id,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when creating the notification!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
      } catch (e) {
        throw e.message;
      }
    },
  },
};
</script>

<style lang="css" scoped>
/* .bg-active {
  background-color: white !important;
  color: black !important;
}
.bgWhite {
  background-color: #6dd984 !important;
} */

.v-card--link:focus {
  background: #6dd984 !important;
}

.v-card--link:focus::before {
  opacity: 0 !important;
}
</style>
